@import './layout-variables.scss';
@import '@rednex/gateway_core/breakpoints';

.menu-container {
  position: fixed;
  margin-left: 0px;
  left: 0;
  top: 0;
  display: flex;
  height: $menuBarHeight;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - $sidebarWidthCollapsed);
  //background-color: $bodyBgColor;
  background-color: #313131;
  z-index: 10000;
}

.main-component-view {
  height: calc(100vh - $menuBarHeight);
  margin-top: $menuBarHeight;
}

.menu-expanded-wrapper {
  display: grid;
  grid-template-columns: 100vw;
}

.menu-hidden-wrapper {
  display: grid;
  grid-template-columns: 100vw;
}

.p-toast {
  top: $menuBarHeight;
  width: fit-content;

  .p-toast-message {
    .p-toast-message-content {
      overflow: auto;
    }
  }
}
