// Main Components Container Styles
$mainComConPadding: 0;
$mainComConGap: 1rem;

// Component Container Styles
$componentConBgColor: transparent;
$componentConPadding: 0.5rem 0.5rem 0rem 0.5rem;
$componentConBorderRadius: 1px;
$componentConBorder: none;
$componentConBoxShadow: none;

// Sidebar Container Styles
$sidebarConBgColor: $sidebarBgColor;
$sidebarConPadding: 1.5rem 2rem;
$sidebarConBorderRadius: 1px;
$sidebarConBorder: none;
$sidebarConBoxShadow: none;
