// Theme Specific Variables
$primaryTextColor: #ffffff;
$secondaryTextColor: #ffffff;

// Light & Dark Mode Theme most relevant Variables
$bgColor: #555555; // Dropdown,Calendar BG etc.
$bodyBgColor: #313131; // Html Body Element
$cardBgColor: #555555; // Card Component
$sidebarBgColor: #5e5e5e70;
$sidebarDropdownBgColor: #5e5e5e;
$disabledBgColor: #606060;
$highlightColor: #7b7b7b;
$hoverColor: #6a6a6a;

// General Colors
$primaryColor: #317cf6;
$primaryDarkColor: #0a64f5;
$primaryDarkerColor: #095adc;

$secondaryColor: #31a8a7;

$greenColor: #47b86c;
$lightGreenColor: #55c67a;
$darkGreenColor: #3ba15d;

$blueColor: #6a86bc;
$lightBlueColor: #7591c9;
$darkBlueColor: #465e8e;

$yellowColor: #ddde44;
$lightYellowColor: #e9e944;
$darkYellowColor: #d2d23a;

$violetColor: #9a77b4;
$lightVioletColor: #a387b9;
$darkVioletColor: #7d5d96;

$redColor: #f24053;
$lightRedColor: #e95d6b;
$darkRedColor: #d93343;

$colors: (
  'blue': #6a86bc,
  'green': #47b86c,
  'yellow': #ddde44,
  'cyan': #31a8a7,
  'pink': #f24053,
  'indigo': #6a86bc,
  'teal': #7dc35b,
  'orange': #f57c00,
  'bluegray': #607d8b,
  'purple': #9a77b4,
  'red': #d82240,
  'primary': $primaryColor,
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  --body-background-color: #{$bodyBgColor};
  --border-radius: #{$borderRadius};
  --content-padding: #{$panelContentPadding};
  --focus-ring: #{$focusShadow};
  --font-family: Avenir Next, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-50: #fafafa;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --inline-spacing: #{$inlineSpacing};
  --maskbg: #{$maskBg};
  --primary-color-text: #ffffff;
  --primary-color: #{$primaryColor};
  --secondary-color: #{$secondaryColor};
  --surface-0: #ffffff;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-50: #fafafa;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-border: #dee2e6;
  --surface-c: #e9ecef;
  --surface-card: #{$cardBgColor};
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --surface-ground: #{$bodyBgColor};
  --surface-hover: #e9ecef;
  --surface-overlay: #ffffff;
  --surface-section: #ffffff;
  --text-color-secondary: #6c757d;
  --text-color: #{$primaryTextColor};
  color-scheme: light;
}
