//Configuration for the font-face of the theme, defaults to the system font so left as blank
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Regular'), local('Avenir-Next-Regular'), url('./fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  src: local('Avenir Next Medium'), local('Avenir-Next-Medium'), url('./fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 300;
  src: local('Avenir Next Light'), local('Avenir-Next-Light'), url('./fonts/AvenirNextLTPro-UltLtCn.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-UltLt.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  src: local('Avenir Next Demi'), local('Avenir-Next-Demi'), url('./fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Demi.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: bold;
  src: local('Avenir Next Bold'), local('Avenir-Next-Bold'), url('./fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('./fonts/AvenirNextLTPro-Bold.woff') format('woff');
}
