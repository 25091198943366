// Styling Main Grid Container
$mainGridPadding: 1rem 0;
$mainGridRowGap: 2rem;
$mainGridBorder: none;
$mainGridBgColor: transparent;

// Grid Space between Elements inside a Row
$gridGap: 1rem;

// Minimum Size of Elements in px or rem before wrap
// Grid of 5 Elements
$grid5ElementMinimumSize: 200px;
