@import '@rednex/gateway_core/breakpoints';

.no-update {
  background-color: #a0a0a0;
}

.headertitle {
  align-items: center;
  display: flex;
  gap: 1rem;
  z-index: 999;
}

.headerlogo {
  height: 40px;
  margin-left: 5px;
}

@include media('<=md') {
  .menubar-mobile {
    position: initial;
  }
}

.p-sidebar-left {
  width: 13rem !important;
}

.navbar-button {
  margin-left: 10px;
  width: 52px !important;
  height: 40px;
}
