@import '@rednex/gateway_core/breakpoints';

.button-create {
  color: $greenColor !important;
}

.button-delete {
  color: $lightRedColor !important;
}

.button-detail {
  color: $primaryColor !important;
}

.button-edit {
  color: $secondaryColor !important;
}

.button-refresh {
  color: $violetColor !important;
}

.badge-activated {
  background-color: $lightGreenColor;
}

.badge-deactivated {
  background-color: $lightRedColor;
}

.p-paginator {
  background-color: $bodyBgColor;
}

.p-datatable-thead {
  background-color: $bodyBgColor;
  top: -1px !important; //darstellungsproblem zwischen header und dem hinzufügen und refresh button
}

.default-dialog {
  width: 30vw;

  @include media('<=md') {
    width: 80vw;
  }
}

.turn-change {
  background-color: #ffff00 !important;
  color: black !important;
}

.best-time {
  background-color: #00ff00 !important;
  color: black !important;
}

.p-component {
  font-size: 0.9rem;
}

.p-selectbutton .p-button.p-highlight {
  border-color: white;
}

.p-selectbutton .p-button.p-highlight:hover {
  border-color: white;
}

.small-padding {
  .p-button {
    padding: 5px !important;
  }
}
