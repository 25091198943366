/* Customizations to the designer theme should be defined here */
.p-component {
  font-family: Avenir Next, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.p-steps .p-steps-item:before {
  border-color: $greenColor !important;
  border-width: 2px !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: $primaryColor !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: $bodyBgColor !important;
  color: $primaryColor !important;
  border: solid 2px $primaryColor !important;
  font-weight: 700;
}

.p-steps-number {
  display: flex;
  align-items: baseline;
}

.p-steps .p-steps-item:before {
  margin-top: -1.1rem !important;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-size: 1.25rem;
}
