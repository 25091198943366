.line {
  height: 100%;
  left: 3.55rem;
  position: absolute;
  border-left: solid 2px $greenColor;
}

.p-fieldset {
  border: none !important;
  padding: 0;
  background: $bodyBgColor;
}

.p-fieldset-toggler {
  display: none !important;
}

.p-fieldset-legend {
  border: none !important;
  background-color: $bodyBgColor !important;
  display: flex;
  align-items: center;
  font-size: 1.25rem;

  .number {
    border-radius: 50% !important;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-left: 0.5rem;
    font-weight: normal;
    display: flex;
    align-items: center;
  }

  a[aria-expanded='true'] {
    color: $primaryColor !important;

    .number {
      border: solid 2px $primaryColor;
      background-color: $bodyBgColor !important;
    }
  }

  a[aria-expanded='false'] {
    color: $greenColor !important;

    .number {
      border: solid 2px $greenColor;
      background-color: $bodyBgColor !important;
    }

    .title {
      color: $greenColor;
    }
  }
}

.p-fieldset-content {
  padding-left: 4rem !important;
  background-color: $bodyBgColor;
}

.info {
  color: $redColor;
}
