//Sidebar variables
$primary-color: $primaryColor;
$base-bg: $bodyBgColor;

$item-color: white;
$item-active-color: $primaryColor;

$icon-bg: $primaryColor;
$icon-active-bg: white;
$icon-width: 24px;
$icon-height: 24px;

@import 'vue-sidebar-menu/src/scss/vue-sidebar-menu.scss';
@import './layout-variables.scss';

//siehe: https://github.com/yaminncco/vue-sidebar-menu
.v-sidebar-menu {
  border: none;
  border-width: 0 1px 0 0;

  .vsm--dropdown {
    background-color: $sidebarDropdownBgColor;
  }

  .vsm--item {
    a {
      svg {
        background-color: transparent !important;
        color: $primaryColor;
      }
    }
  }

  .vsm--link_active,
  .vsm--link_open {
    background-color: $primaryColor !important;

    svg {
      color: $secondaryTextColor !important;
    }

    .vsm--title {
      color: $secondaryTextColor;
      font-weight: normal;
    }
  }

  .vsm--title {
    color: $primaryTextColor;
    font-size: 1rem;
    font-weight: normal;
  }

  .vsm--scroll-thumb {
    background-color: $primaryColor !important;
  }
}
