@import '@rednex/gateway_core/breakpoints';

body {
  margin: 0;
  font-family: var(--font-family, 'Arial, sans-serif');
  color: var(--text-color);
}

a {
  color: var(--text-color);
}

/* jhipster-needle-scss-add-main JHipster will add new css style */
.app-header {
  margin-top: 1rem;
  height: 62px;
  width: 100%;
}

.extended-search-panel {
  margin-top: 1rem;
  padding: 20px;
  height: auto;
}

.container-border {
  background-color: white;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: transparent;
}

.p-menubar-root-list {
  @include media('<=md') {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 48px !important;
  }
}

.p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  z-index: 999;
}

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}
