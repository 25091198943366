@import '@content/scss/mytheme-dark/theme.scss';
@import '@content/scss/mytheme-dark/fonts.scss';

@import './components/buttons.scss';
@import './components/containers.scss';
@import './components/grids.scss';
@import './components/headlines.scss';
@import './components/verticalStepper.scss';

@import './main-layout/main-view.scss';
@import './main-layout/sidebar.scss';

@import '@rednex/gateway_core/rs_styles';

@import './customs.scss';

body {
  background-color: $bodyBgColor;
}

.p-disabled,
.p-component:disabled {
  background-color: $disabledBgColor;
}
