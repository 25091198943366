/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.5rem 1rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.357rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0.5rem 0;

/// Background of a button
/// @group button
$buttonBg: $primaryColor;

/// Text color of a button
/// @group button
$buttonTextColor: $secondaryTextColor;

/// Border of a button
/// @group button
$buttonBorder: 1px solid $primaryColor;

/// Background of a button in hover state
/// @group button
$buttonHoverBg: $primaryDarkColor;

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $secondaryTextColor;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $primaryDarkColor;

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: $primaryDarkerColor;

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $secondaryTextColor;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $primaryDarkerColor;

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.2;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: 0.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder: 1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: #6c757d;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: #e9ecef;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: #dee2e6;

/// Background of a secondary button
/// @group button
$secondaryButtonBg: $primaryColor;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: #ffffff;

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid $primaryColor;

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: $primaryDarkColor;

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: #ffffff;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: $primaryDarkColor;

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: $primaryDarkerColor;

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: #ffffff;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: $primaryDarkerColor;

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem #b0bec5;

/// Background of an info button
/// @group button
$infoButtonBg: $blueColor;

/// Text color of an info button
/// @group button
$infoButtonTextColor: #ffffff;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid $blueColor;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: $lightBlueColor;

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: #ffffff;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: $lightBlueColor;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: $darkBlueColor;

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: #ffffff;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: $darkBlueColor;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: $greenColor;

/// Text color of a success button
/// @group button
$successButtonTextColor: #ffffff;

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid $greenColor;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: $lightGreenColor;

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: #ffffff;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: $lightGreenColor;

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: $darkGreenColor;

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: #ffffff;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: $darkGreenColor;

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);

/// Background of a warning button
/// @group button
$warningButtonBg: $yellowColor;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: $textColor;

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid $yellowColor;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: $lightYellowColor;

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: $textColor;

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: $lightYellowColor;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: $darkYellowColor;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $textColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: $darkYellowColor;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg: $violetColor;

/// Text color of a help button
/// @group button
$helpButtonTextColor: #ffffff;

/// Border of a help button
/// @group button
$helpButtonBorder: 1px solid $violetColor;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: $lightVioletColor;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: #ffffff;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: $lightVioletColor;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: $darkVioletColor;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: #ffffff;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: $darkVioletColor;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: 0 0 0 0.2rem $lightVioletColor;

/// Background of a danger button
/// @group button
$dangerButtonBg: $redColor;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: #ffffff;

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid $redColor;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: $lightRedColor;

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: #ffffff;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: $lightRedColor;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: $darkRedColor;

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: #ffffff;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: $darkRedColor;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);

/// Text color of a link button
/// @group button
$linkButtonColor: $primaryDarkerColor;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: $primaryDarkerColor;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration: underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: $bgColor;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid #ced4da;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: $hoverColor;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: #ced4da;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $secondaryTextColor;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $secondaryTextColor;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $secondaryTextColor;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $secondaryTextColor;

/// Width of the speed dial button
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of the speed dial button
/// @group button
$speedDialButtonHeight: 4rem;

/// Icon size of the speed dial button
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of a speed dial item
/// @group button
$speedDialActionWidth: 3rem;

/// Height of a speed dial item
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of a speed dial item
/// @group button
$speedDialActionBg: #ffffff;

/// Background color of a speed dial item in hover state
/// @group button
$speedDialActionHoverBg: #f5f5f5;

/// Text color of a speed dial item
/// @group button
$speedDialActionTextColor: #212121;

/// Text color of a speed dial item in hover state
/// @group button
$speedDialActionTextHoverColor: #212121;
